import * as types from './action_types'
import { defaultInstance as defaultFavoritesClient } from '@xo-union/sdk-favorites';
import { defaultService as membershipService } from '@xo-union/sdk-membership';

export function clearFavorites() {
  return (dispatch, getState) => {
    dispatch(clear());
  };
}

export function fetchFavorites() {
  return (dispatch, getState, { favoritesClient = defaultFavoritesClient } = {}) => {
    let memberId = getState().membership.member.id;
    let sessionToken = getSessionToken();
    let productIds = getProductIds();
    let requestData = { sessionToken: sessionToken, query: { member_uuid: memberId, object_ids: productIds.toString() } };

    favoritesClient
      .get(requestData)
      .then(response => {
        dispatch(fetchSuccess(response.body.favorites));
      });
  };
}

export function loveImage(data, trackingData={}) {
  return (dispatch, getState, { favoritesClient = defaultFavoritesClient } = {}) => {
    data.member_uuid = getState().membership.member.id;
    const requestData = { sessionToken: getSessionToken(), data: data };

    favoritesClient
      .create(requestData)
      .then(response => {
        trackingData.source = trackingData.source.replace('TK_PLACEHOLDRE_PAGE_TYPE', window.XO.analytics.filterType.replace('list', 'page')) //the source name is not the same with click card event, this is 'page' and click card call 'list'
        dispatch(love(response.body.favorites[0]));
        analytics.track('Add to Favorite', trackingData)
      });
  };
}

export function unloveImage(favorite_id, product_id, trackingData={}) {
  return (dispatch, getState, { favoritesClient = defaultFavoritesClient } = {}) => {
    const requestData = { sessionToken: getSessionToken(), favoriteUUID: favorite_id, query: { member_uuid: getState().membership.member.id } };

    favoritesClient
      .destroy(requestData)
      .then(response => {
        trackingData.source = trackingData.source.replace('TK_PLACEHOLDRE_PAGE_TYPE', window.XO.analytics.filterType.replace('list', 'page'))
        dispatch(unlove(favorite_id));
        analytics.track('Remove from Favorite', trackingData)
      });
  };
}

export function clearToast() {
  return (dispatch, getState) => {
    dispatch({ type: types.CLEAR_TOAST });
  }
}

function clear() {
  return { type: types.CLEAR };
}

function love(favorite) {
  return {
    type: types.LOVE,
    favorite
  };
}

function unlove(favorite_id) {
  return {
    type: types.UNLOVE,
    favorite_id
  };
}

function fetchSuccess(favorites) {
  return {
    type: types.FETCH_SUCCESS,
    favorites
  };
}

function getProductIds() {
  var ids = []
  const pageType = XO.metadata.page_data.kind;
  if ( pageType === 'detail' || pageType === 'album' ) { ids.push(XO.metadata.entities.primary_entity_id) }

  $('.photo-card').each(function (index, obj) { ids.push($(obj).data('fav-object-id')) })
  let deleteIndex = ids.indexOf('primary_entity_id');
  if (deleteIndex > -1) {
    ids.splice(deleteIndex, 1)
  }

  return ids
}

function getSessionToken(){
  return membershipService.getSessionToken();
}
